.mymodal-table {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #fff;
    background: #fff;
    overflow: auto;
    outline: none;
    width: 85% !important;
    height: auto;
    border-radius: 10px;
}

@media (max-width: 1023px) {
    .mymodal-table {
        width: 95% !important;
        margin-top: 1.5rem;
        padding-bottom: 16px;
    }
}

.modal-min-w {
    min-width: 45%;
}
    
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10050000 !important;
}
    
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    z-index: 10050000;
}

.ReactModal__Content {
    max-height: calc(100vh - 2rem);
    overflow-y: auto;
}
    
.ReactModal__Overlay--after-open {
    opacity: 1;
}
    
.ReactModal__Overlay--before-close {
    opacity: 0;
}

.model-item-cont {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    background: transparent;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.modal-close-bt {
    position: absolute;
    top: 15px;
    right: 17px;
    /* display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 31px;
    height: 31px; */
    cursor: pointer;
    transition: background .3s ease-in-out;
}

/* .modal-close-btn:hover {
    background-color: #ddd;
} */

.modal-close-btn:active {
    background-color: #aaa;
}