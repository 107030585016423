.checkboxContainer {
    background: transparent ;
    width: 16px;
    height: 16px;
}

.lbContainer {
    display: inline-flex;
    align-items: center;
    font-size: 10vmin;
}

.lbContainer > input {
    appearance: none;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border: 2px solid #D0D5DD;
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 300ms ease-in-out;
}

.lbContainer > input::after {
    content: "";
    width: 15px;
    height: 15px;
    background-color: #fff;
    transform: scale(0);
    transform-origin: 80% top left;
    transition: transform 300ms ease-in-out;
    clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
}

.lbContainer > input:checked {
    background-color: #7F56D9;
    border-color: #7F56D9;
}

.lbContainer > input:checked::after {
    transform: scale(1);
}