.css-1okebmr-indicatorSeparator {
    display: none;
}

.custom-select-container > div {
    height: auto;
    border-radius: 8px;
}

.custom-select-container > div > div {
    padding-inline: 0px;
}

.custom-select-container > div div:nth-child(2) {
    display: flex;
    align-items: center;
}

.custom-select-container > div > div > div {
    color: #667085;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-inline: 12px;
}

.custom-select-container > div > div  div:nth-child(2) {
    height: 40px;
}